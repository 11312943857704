import { createClient, ClientConfig } from "@sanity/client";

const config: ClientConfig = {
  projectId: "x0u4j58p",
  dataset: "production",
  apiVersion: "2024-01-01",
  useCdn: false,
};

export const client = createClient(config);

