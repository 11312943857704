
import React, { useEffect, useState } from 'react';
import imageUrlBuilder from "@sanity/image-url";
import { SanityDocument } from "@sanity/client";
import { SanityImageSource } from "@sanity/image-url/lib/types/types";
import { PortableText } from "@portabletext/react";

import { client } from "../sanity/client";

const POSTS_QUERY = `*[
    _type == "post"
    && defined(slug.current)
  ]|order(publishedAt desc)[0...12]{_id, title, slug, image, body, publishedAt}`;
const { projectId, dataset } = client.config();
const urlFor = (source: SanityImageSource) =>
projectId && dataset
? imageUrlBuilder({ projectId, dataset }).image(source)
: null;

// const posts = client.fetch<SanityDocument[]>();

const getPosts = async (client) => {
    const data = await client.fetch(POSTS_QUERY, {});
    console.log(data)
    return data
}

// const data = await client.fetch(POSTS_QUERY);
// const posts = await getPosts(client)
const CMSTest = () => {
    // useEffect(() => {
    //     client.fetch<number>(POSTS_QUERY, {})
    //       .then((data) => console.log(data))
    //       .catch(console.error);
    //   }, []);
    const [posts, setPosts] = useState<SanityDocument[]>();
    useEffect(() => {
        getPosts(client).then((res) => setPosts(res))
      }, []);


    return (
    <main className="container mx-auto min-h-screen max-w-3xl p-8">
      <h1 className="text-4xl font-bold mb-8">Posts</h1>
      <ul className="flex flex-col gap-y-4">
        {posts?.map((post) => (
          <li className="hover:underline" key={post._id}>
            {/* <Link to={`/${post.slug.current}`}> */}
              <h2 className="text-xl font-semibold">{post.title}</h2>
              {post.image && (
                <img
                src={urlFor(post.image)?.url()}
                width="400"
                />
            )}
            <PortableText value={post.body}/>
            <p>{new Date(post.publishedAt).toLocaleDateString()}</p>
            {/* </Link> */}
          </li>
        ))}
      </ul>
    </main>
    )
}

export default CMSTest;